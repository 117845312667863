import { CONFIG } from './config'

export const VALIDGUESSES = [
	'AABER',
	'AACRE',
	'AALER',
	'AAMAL',
	'AAMRY',
	'AANER',
	'AAVAL',
	'AAVIS',
	'ABACK',
	'ABADE',
	'ABAID',
	'ABANE',
	'ABASE',
	'ABBEY',
	'ABBLE',
	'ABEEN',
	'ABEET',
	'ABEUN',
	'ABIDE',
	'ABIEL',
	'ABIES',
	'ABIET',
	'ABIGH',
	'ABINE',
	'ABLES',
	'ABLOW',
	'ABOIL',
	'ABOON',
	'ABOOT',
	'ABOVE',
	'ABUIN',
	'ABUIT',
	'ABUNE',
	'ACAMY',
	'ACCEP',
	'ACCRE',
	'ACHAN',
	'ACHEN',
	'ACKER',
	'ACKIE',
	'ACKRE',
	'ACKWA',
	'ACRAG',
	'ACRER',
	'ADBER',
	'ADDIL',
	'ADDLE',
	'ADEED',
	'ADIST',
	'ADOON',
	'ADOUN',
	'ADOWN',
	'ADÜMS',
	'AEGER',
	'AESIN',
	'AFFEN',
	'AFFGO',
	'AFFIN',
	'AFORE',
	'AFTAK',
	'AFTEN',
	'AGAIN',
	'AGANE',
	'AGANT',
	'AGEAN',
	'AGENT',
	'AGERS',
	'AGGEK',
	'AGGLE',
	'AGLEE',
	'AGLEY',
	'AGREE',
	'AGRIP',
	'AGRUE',
	'AHAME',
	'AHAUD',
	'AHAUL',
	'AHEAT',
	'AHEID',
	'AHENT',
	'AHIND',
	'AHINT',
	'AICHT',
	'AIDAM',
	'AIDLE',
	'AIFER',
	'AIGHT',
	'AIGLE',
	'AIKEL',
	'AIKEN',
	'AIKER',
	'AIKLE',
	'AILIE',
	'AINCE',
	'AINNA',
	'AINTY',
	'AIRCH',
	'AIREL',
	'AIRIE',
	'AIRLE',
	'AIRMY',
	'AIRNY',
	'AIRRA',
	'AIRSE',
	'AIRTH',
	'AIRTY',
	'AISLE',
	'AISSY',
	'AITEN',
	'AIVAL',
	'AIVER',
	'AIVES',
	'AIVIS',
	'AIWAL',
	'AIXLE',
	'AIZER',
	'AIZLE',
	'AKAMY',
	'AKKER',
	'AKKIR',
	'AKSIS',
	'ALACE',
	'ALAEK',
	'ALAEN',
	'ALAFT',
	'ALAKE',
	'ALANE',
	'ALANG',
	'ALEEN',
	'ALESS',
	'ALILT',
	'ALISS',
	'ALIST',
	'ALLAN',
	'ALLAR',
	'ALLEN',
	'ALLER',
	'ALLOO',
	'ALLOW',
	'ALOOR',
	'ALOWE',
	'ALRAN',
	'ALTER',
	'ALUNT',
	'ALVES',
	'ALYTH',
	'AMAND',
	'AMANG',
	'AMBEL',
	'AMBER',
	'AMBLE',
	'AMBRY',
	'AMERS',
	'AMEST',
	'AMIDS',
	'AMIND',
	'AMISS',
	'AMMEL',
	'AMMLE',
	'AMNIN',
	'AMONG',
	'AMOUS',
	'AMPEL',
	'AMPER',
	'AMSHO',
	'AMYDS',
	'ANAWN',
	'ANDER',
	'ANDOO',
	'ANDOR',
	'ANDRA',
	'ANELY',
	'ANENT',
	'ANERY',
	'ANETH',
	'ANEUW',
	'ANGEL',
	'ANGER',
	'ANGUS',
	'ANISE',
	'ANIST',
	'ANKER',
	'ANNAL',
	'ANNAT',
	'ANNAY',
	'ANNET',
	'ANNIE',
	'ANOFF',
	'ANOUR',
	'ANTER',
	'ANTLE',
	'ANTRA',
	'ANYAU',
	'ANYOW',
	'AOVER',
	'APPEN',
	'APPER',
	'APPIN',
	'APRIN',
	'AQUAL',
	'ARAGE',
	'AREAR',
	'AREND',
	'ARGIE',
	'ARGON',
	'ARLED',
	'ARLES',
	'ARNIT',
	'ARNOT',
	'ARNUT',
	'AROUN',
	'AROUT',
	'AROWN',
	'ARRAE',
	'ARRAS',
	'ARRED',
	'ARRIE',
	'ARSET',
	'ARVAL',
	'ARVIE',
	'ASHAD',
	'ASHET',
	'ASICK',
	'ASIDE',
	'ASKIN',
	'ASKOY',
	'ASKUT',
	'ASLEY',
	'ASLIN',
	'ASPAR',
	'ASPER',
	'ASSIE',
	'ASTID',
	'ASWEE',
	'ASYZE',
	'ATENT',
	'ATGAN',
	'ATHER',
	'ATHIN',
	'ATOUR',
	'ATSET',
	'ATTER',
	'ATTIE',
	'ATTIR',
	'ATTLE',
	'ATTRY',
	'ATWAE',
	'ATYAR',
	'AUBER',
	'AUCHT',
	'AUDIE',
	'AUGHT',
	'AULIN',
	'AUMOS',
	'AUMRA',
	'AUMRY',
	'AUMUS',
	'AUNTY',
	'AUREA',
	'AVAST',
	'AVERN',
	'AVIDE',
	'AVISH',
	'AVNET',
	'AVOCH',
	'AVOUD',
	'AVREE',
	'AVRIN',
	'AWAAR',
	'AWALD',
	'AWALT',
	'AWANT',
	'AWARD',
	'AWART',
	'AWAUK',
	'AWAUR',
	'AWBER',
	'AWBUN',
	'AWEEL',
	'AWELL',
	'AWFIE',
	'AWHAN',
	'AWILL',
	'AWITE',
	'AWKER',
	'AWKIR',
	'AWMAN',
	'AWMOS',
	'AWMUS',
	'AWNER',
	'AWNIE',
	'AWPIE',
	'AWSUM',
	'AWYTE',
	'AYEST',
	'AYLAY',
	'AYLER',
	'AYOKE',
	'AYOND',
	'AYONT',
	'AYWIT',
	'BABBS',
	'BABBY',
	'BABEE',
	'BABIE',
	'BACKS',
	'BADGE',
	'BADLY',
	'BAELT',
	'BAENK',
	'BAESS',
	'BAEST',
	'BAFFY',
	'BAGGY',
	'BAGIE',
	'BAGLE',
	'BAGSY',
	'BAHAA',
	'BAICH',
	'BAIKY',
	'BAING',
	'BAIRD',
	'BAIRN',
	'BAISE',
	'BAISS',
	'BAIST',
	'BAITH',
	'BAIZE',
	'BAJAN',
	'BAKEN',
	'BAKER',
	'BAKEY',
	'BAKIE',
	'BALAX',
	'BALDI',
	'BALLY',
	'BALNE',
	'BALOO',
	'BALOW',
	'BALTI',
	'BAMBO',
	'BAMFF',
	'BANDY',
	'BANED',
	'BANEY',
	'BANFF',
	'BANGI',
	'BANGY',
	'BANIE',
	'BANJO',
	'BANKS',
	'BANNA',
	'BANNO',
	'BANTY',
	'BARDS',
	'BARDY',
	'BARGE',
	'BARMY',
	'BARON',
	'BARRA',
	'BARRY',
	'BARSK',
	'BARTY',
	'BASIL',
	'BASLE',
	'BASTE',
	'BASTY',
	'BATCH',
	'BATIE',
	'BATON',
	'BATTI',
	'BATTS',
	'BATTY',
	'BAUCH',
	'BAUGH',
	'BAULD',
	'BAULK',
	'BAUND',
	'BAURM',
	'BAUSY',
	'BAUTS',
	'BAUTY',
	'BAVER',
	'BAVIN',
	'BAWAW',
	'BAWIE',
	'BAWLY',
	'BAWNS',
	'BAWSY',
	'BAWTY',
	'BAWYN',
	'BAYIN',
	'BAYLE',
	'BAYOO',
	'BAZIL',
	'BEANE',
	'BEARD',
	'BEARE',
	'BEARG',
	'BEARN',
	'BEASE',
	'BEASS',
	'BEAST',
	'BEATH',
	'BEBBS',
	'BECHE',
	'BECHT',
	'BEDDY',
	'BEECE',
	'BEECH',
	'BEEIN',
	'BEELD',
	'BEELL',
	'BEENE',
	'BEENY',
	'BEERY',
	'BEESE',
	'BEEST',
	'BEETH',
	'BEGIN',
	'BEGUN',
	'BEHIN',
	'BEHSS',
	'BEHUV',
	'BEICH',
	'BEIGH',
	'BEILD',
	'BEILL',
	'BEING',
	'BEISE',
	'BEISH',
	'BEISS',
	'BEIST',
	'BEJAN',
	'BEKEL',
	'BELAW',
	'BELAY',
	'BELCH',
	'BELGE',
	'BELGH',
	'BELIE',
	'BELJA',
	'BELKS',
	'BELLA',
	'BELLI',
	'BELLY',
	'BELOW',
	'BELYA',
	'BENCE',
	'BENCH',
	'BENDY',
	'BENEW',
	'BENGY',
	'BENJE',
	'BENKL',
	'BENNA',
	'BENNY',
	'BENSE',
	'BENTY',
	'BEOCH',
	'BEOWL',
	'BEREW',
	'BERGE',
	'BERMY',
	'BERRS',
	'BERRY',
	'BERVE',
	'BESEL',
	'BESET',
	'BESLE',
	'BESOM',
	'BESSY',
	'BETED',
	'BETEK',
	'BETOG',
	'BETTS',
	'BETTY',
	'BEUCH',
	'BEUCK',
	'BEUGH',
	'BEULD',
	'BEURD',
	'BEUSE',
	'BEUSS',
	'BEUST',
	'BEVEL',
	'BEVER',
	'BEVIE',
	'BEVIL',
	'BEWRY',
	'BEWST',
	'BEYCE',
	'BEYON',
	'BEZLE',
	'BIACH',
	'BIBBY',
	'BIBLE',
	'BICHT',
	'BIDDA',
	'BIDIN',
	'BIEIN',
	'BIELD',
	'BIELY',
	'BIFFY',
	'BIGLY',
	'BIHAN',
	'BIKER',
	'BIKKO',
	'BILCH',
	'BILER',
	'BILEY',
	'BILGE',
	'BILLE',
	'BILLY',
	'BILSH',
	'BIMME',
	'BIMMY',
	'BINCH',
	'BINGE',
	'BINGS',
	'BINNA',
	'BINTY',
	'BIRCE',
	'BIRCY',
	'BIRKY',
	'BIRLA',
	'BIRNY',
	'BIRRY',
	'BIRSE',
	'BIRSI',
	'BIRSK',
	'BIRSS',
	'BIRST',
	'BIRTH',
	'BIRTI',
	'BIRTY',
	'BIRZY',
	'BISSY',
	'BITCH',
	'BITEL',
	'BITTS',
	'BIZEN',
	'BIZZY',
	'BJĀKI',
	'BJAKK',
	'BJOKL',
	'BJORG',
	'BLAAD',
	'BLAAG',
	'BLABE',
	'BLACK',
	'BLADD',
	'BLADE',
	'BLAEB',
	'BLAEM',
	'BLAER',
	'BLAES',
	'BLAEU',
	'BLAFF',
	'BLAHO',
	'BLAID',
	'BLAIK',
	'BLAIN',
	'BLAIR',
	'BLAIT',
	'BLAKE',
	'BLAME',
	'BLAND',
	'BLANK',
	'BLARE',
	'BLARN',
	'BLART',
	'BLASE',
	'BLASH',
	'BLASS',
	'BLAST',
	'BLATE',
	'BLAUD',
	'BLAWD',
	'BLAWN',
	'BLAWP',
	'BLAWY',
	'BLAZE',
	'BLEAD',
	'BLEAR',
	'BLEAT',
	'BLECK',
	'BLEEB',
	'BLEED',
	'BLEEK',
	'BLEEM',
	'BLEER',
	'BLEET',
	'BLEGA',
	'BLEHH',
	'BLEIB',
	'BLEID',
	'BLEIR',
	'BLEIS',
	'BLEKK',
	'BLENT',
	'BLEST',
	'BLETT',
	'BLEUD',
	'BLIBE',
	'BLIDE',
	'BLIND',
	'BLINK',
	'BLINT',
	'BLIPE',
	'BLIRT',
	'BLISS',
	'BLOAR',
	'BLOBE',
	'BLOCK',
	'BLOGG',
	'BLOIT',
	'BLOKK',
	'BLONT',
	'BLOOD',
	'BLOOM',
	'BLORE',
	'BLORT',
	'BLOSS',
	'BLOST',
	'BLOUR',
	'BLOUT',
	'BLOWT',
	'BLUDE',
	'BLUFF',
	'BLUGH',
	'BLUID',
	'BLUIE',
	'BLUIF',
	'BLUIM',
	'BLUIT',
	'BLUME',
	'BLÜME',
	'BLUMF',
	'BLUNK',
	'BLUNT',
	'BLURA',
	'BLURT',
	'BLUSH',
	'BLUTE',
	'BLYBE',
	'BLYDE',
	'BLYID',
	'BLYPE',
	'BLYTE',
	'BLYTH',
	'BOADY',
	'BOARD',
	'BOART',
	'BOAST',
	'BOBBY',
	'BOCHT',
	'BODDA',
	'BODDI',
	'BODEK',
	'BODEN',
	'BODIE',
	'BODIN',
	'BODLE',
	'BODSY',
	'BOFFT',
	'BOGAN',
	'BOGEL',
	'BOGEY',
	'BOGIE',
	'BOGIN',
	'BOGLE',
	'BOGLO',
	'BOGLY',
	'BOHOY',
	'BOICH',
	'BOICK',
	'BOILT',
	'BOINE',
	'BOIRT',
	'BOKEN',
	'BOKIE',
	'BOLEY',
	'BOLGA',
	'BOLKI',
	'BOMAN',
	'BOMER',
	'BOMMI',
	'BONCE',
	'BONEK',
	'BONEY',
	'BONIE',
	'BONKS',
	'BONNY',
	'BONSH',
	'BOOCE',
	'BOOCH',
	'BOODY',
	'BOOER',
	'BOOET',
	'BOOFF',
	'BOOIL',
	'BOOLD',
	'BOOLL',
	'BOOLS',
	'BOOLY',
	'BOOMB',
	'BOONA',
	'BOOND',
	'BOONE',
	'BOONS',
	'BOONY',
	'BOORD',
	'BOOSE',
	'BOOST',
	'BOOTO',
	'BOOTY',
	'BOOZY',
	'BORAG',
	'BORAL',
	'BORCH',
	'BOREK',
	'BORIE',
	'BORRA',
	'BORRY',
	'BOSEY',
	'BOSIE',
	'BOSOM',
	'BOSSY',
	'BOTEL',
	'BOTHY',
	'BOTLI',
	'BOUAR',
	'BOUCH',
	'BOUCK',
	'BOUER',
	'BOUET',
	'BOUFF',
	'BOUIT',
	'BOULE',
	'BOULL',
	'BOULT',
	'BOUND',
	'BOUNE',
	'BOUNG',
	'BOURD',
	'BOUSE',
	'BOUST',
	'BOUZY',
	'BOWAL',
	'BOWAN',
	'BOWAT',
	'BOWCE',
	'BOWCH',
	'BOWDY',
	'BOWEN',
	'BOWER',
	'BOWET',
	'BOWEY',
	'BOWFF',
	'BOWHH',
	'BOWIE',
	'BOWIN',
	'BOWIT',
	'BOWLS',
	'BOWLY',
	'BOWNE',
	'BOWRY',
	'BOWSE',
	'BOWSH',
	'BOWTE',
	'BOWZY',
	'BOXIE',
	'BOYNE',
	'BOZAN',
	'BOZEN',
	'BOZIE',
	'BRAAD',
	'BRAAL',
	'BRAAS',
	'BRACE',
	'BRACK',
	'BRADE',
	'BRADS',
	'BRAED',
	'BRAGH',
	'BRAID',
	'BRAIF',
	'BRAIG',
	'BRAIK',
	'BRAIL',
	'BRAIN',
	'BRAKE',
	'BRAKK',
	'BRAND',
	'BRANG',
	'BRANK',
	'BRANT',
	'BRASH',
	'BRAST',
	'BRATH',
	'BRATL',
	'BRATT',
	'BRAUN',
	'BRAVE',
	'BRAWD',
	'BRAWL',
	'BRAWN',
	'BRAWS',
	'BRAXI',
	'BRAXY',
	'BRAZE',
	'BREAD',
	'BRECK',
	'BREDD',
	'BREED',
	'BREEF',
	'BREEG',
	'BREEK',
	'BREEL',
	'BREEM',
	'BREER',
	'BREET',
	'BREGD',
	'BREID',
	'BREIK',
	'BREIN',
	'BREKK',
	'BREME',
	'BREND',
	'BRENN',
	'BRENT',
	'BRERD',
	'BRESS',
	'BRETT',
	'BREUK',
	'BREUT',
	'BRICK',
	'BRIDE',
	'BRIDS',
	'BRIED',
	'BRIEF',
	'BRIEK',
	'BRIEN',
	'BRIGD',
	'BRIGG',
	'BRILE',
	'BRILL',
	'BRIME',
	'BRIND',
	'BRING',
	'BRINK',
	'BRINN',
	'BRINT',
	'BRISE',
	'BRISK',
	'BRISS',
	'BRITJ',
	'BRIZE',
	'BRIZZ',
	'BROAD',
	'BROAG',
	'BROAK',
	'BROAS',
	'BROCH',
	'BROCK',
	'BRODD',
	'BRODE',
	'BROGG',
	'BROGH',
	'BROKE',
	'BROOI',
	'BROOK',
	'BROOM',
	'BROON',
	'BROSE',
	'BROSY',
	'BROTH',
	'BROTJ',
	'BROUT',
	'BROWE',
	'BROWL',
	'BRUCE',
	'BRUCH',
	'BRUCK',
	'BRUDE',
	'BRUGG',
	'BRUGH',
	'BRÜID',
	'BRUIK',
	'BRÜIL',
	'BRUIT',
	'BRUKK',
	'BRÜLE',
	'BRUME',
	'BRUMF',
	'BRUND',
	'BRUNG',
	'BRUNT',
	'BRUSE',
	'BRUSH',
	'BRŪSK',
	'BRUST',
	'BRUZE',
	'BUBBA',
	'BUBBY',
	'BUCHT',
	'BUCKI',
	'BUCKL',
	'BUCKS',
	'BUCKY',
	'BÜDDI',
	'BUDDO',
	'BUDDY',
	'BÜEST',
	'BUFFY',
	'BUFLE',
	'BUGGY',
	'BUGHT',
	'BUGLE',
	'BUICK',
	'BUIDY',
	'BUILD',
	'BUILO',
	'BUILY',
	'BUINK',
	'BUIRD',
	'BUIRK',
	'BUISE',
	'BUIST',
	'BUITH',
	'BUKAT',
	'BUKKI',
	'BULAG',
	'BULCH',
	'BULEK',
	'BULFY',
	'BULGA',
	'BULGE',
	'BŪLIK',
	'BULIN',
	'BULKY',
	'BULLA',
	'BULLE',
	'BULLY',
	'BULTY',
	'BUMFY',
	'BUMLE',
	'BUMMI',
	'BUMMO',
	'BUMMY',
	'BUMPH',
	'BUMPY',
	'BUNCE',
	'BUNCH',
	'BUNGS',
	'BUNGY',
	'BUNKS',
	'BUNSE',
	'BUNTA',
	'BUNTY',
	'BUOCK',
	'BURAL',
	'BURDE',
	'BUREK',
	'BÜREP',
	'BURGH',
	'BURIE',
	'BURKY',
	'BURLO',
	'BURLY',
	'BURNT',
	'BURNY',
	'BÜROL',
	'BUROO',
	'BURRA',
	'BURRO',
	'BURRY',
	'BURSE',
	'BURST',
	'BURTH',
	'BUSHI',
	'BUSHT',
	'BUSKS',
	'BUSKY',
	'BUSOM',
	'BUSSY',
	'BUTCH',
	'BUTEA',
	'BUTTO',
	'BUTTS',
	'BUTTY',
	'BUZLE',
	'BWEIL',
	'BYAAK',
	'BYACK',
	'BYEUK',
	'BYKAT',
	'BYLIE',
	'BYLLY',
	'BYOCH',
	'BYOCK',
	'BYOOK',
	'BYOUS',
	'BYSEN',
	'BYSET',
	'BYSSE',
	'BYUCK',
	'CABAG',
	'CABAL',
	'CABAR',
	'CABER',
	'CABIN',
	'CABLE',
	'CABRY',
	'CACHE',
	'CACKY',
	'CADDA',
	'CADDY',
	'CADGE',
	'CADGY',
	'CADIE',
	'CADIS',
	'CADJE',
	'CADLE',
	'CAEVE',
	'CAGIE',
	'CAHOW',
	'CAIBE',
	'CAICE',
	'CAIGY',
	'CAIRD',
	'CAIRL',
	'CAIRN',
	'CAIRT',
	'CAISS',
	'CAISY',
	'CAKER',
	'CAKEY',
	'CAKIE',
	'CAKIT',
	'CALAW',
	'CALIK',
	'CALME',
	'CALMY',
	'CALOO',
	'CALSH',
	'CALVE',
	'CAMEL',
	'CAMMY',
	'CAMPY',
	'CANCE',
	'CANDY',
	'CANNA',
	'CANNY',
	'CANSE',
	'CANSH',
	'CANTY',
	'CAPER',
	'CAPES',
	'CAPUL',
	'CARDY',
	'CAREL',
	'CARIE',
	'CARLE',
	'CARRY',
	'CARSE',
	'CARSY',
	'CARTE',
	'CARVY',
	'CASAE',
	'CASEY',
	'CASIE',
	'CASSA',
	'CASSY',
	'CASTA',
	'CASTO',
	'CATCH',
	'CATER',
	'CATHE',
	'CATMA',
	'CATTY',
	'CAUFF',
	'CAUKY',
	'CAULD',
	'CAULK',
	'CAULM',
	'CAULP',
	'CAURE',
	'CAUSA',
	'CAUSE',
	'CAUVE',
	'CAVAG',
	'CAVEE',
	'CAVEL',
	'CAVER',
	'CAVEY',
	'CAVIE',
	'CAVIL',
	'CAWAW',
	'CAWDY',
	'CAWVE',
	'CEARN',
	'CECIL',
	'CEDAR',
	'CEERY',
	'CELTS',
	'CERRY',
	'CERTY',
	'CHAAR',
	'CHAAS',
	'CHACK',
	'CHADD',
	'CHAEP',
	'CHAET',
	'CHAFF',
	'CHAFT',
	'CHAIF',
	'CHAIN',
	'CHAIP',
	'CHAIT',
	'CHAMP',
	'CHANG',
	'CHANT',
	'CHAPE',
	'CHAPS',
	'CHARD',
	'CHARK',
	'CHARR',
	'CHART',
	'CHASE',
	'CHATE',
	'CHAUD',
	'CHAUL',
	'CHAUM',
	'CHAUT',
	'CHAVE',
	'CHAWL',
	'CHAWS',
	'CHEAT',
	'CHECK',
	'CHEEF',
	'CHEEK',
	'CHEEL',
	'CHEEM',
	'CHEEN',
	'CHEEP',
	'CHEER',
	'CHEET',
	'CHEIK',
	'CHEIL',
	'CHEIM',
	'CHEIP',
	'CHEIR',
	'CHEIS',
	'CHEIT',
	'CHELF',
	'CHEMY',
	'CHERK',
	'CHERT',
	'CHESS',
	'CHEST',
	'CHEWS',
	'CHICE',
	'CHICK',
	'CHIDE',
	'CHIEF',
	'CHIEL',
	'CHIFF',
	'CHIKK',
	'CHILE',
	'CHILL',
	'CHILP',
	'CHIME',
	'CHIMP',
	'CHINA',
	'CHINE',
	'CHINK',
	'CHINT',
	'CHIPS',
	'CHIRD',
	'CHIRE',
	'CHIRK',
	'CHIRL',
	'CHIRM',
	'CHIRP',
	'CHIRR',
	'CHIRT',
	'CHISE',
	'CHISP',
	'CHIST',
	'CHITS',
	'CHOAK',
	'CHOBS',
	'CHOCH',
	'CHOCK',
	'CHOKE',
	'CHOLD',
	'CHOOK',
	'CHOOL',
	'CHOOP',
	'CHOPE',
	'CHOPS',
	'CHORE',
	'CHORK',
	'CHORP',
	'CHOUK',
	'CHOUL',
	'CHOUP',
	'CHOVY',
	'CHOWE',
	'CHOWK',
	'CHOWL',
	'CHOWS',
	'CHOYS',
	'CHREE',
	'CHUCH',
	'CHUCK',
	'CHUFF',
	'CHULL',
	'CHUMP',
	'CHUPP',
	'CHURK',
	'CHURL',
	'CHURR',
	'CHURT',
	'CHUSE',
	'CHYNE',
	'CHYRE',
	'CINNO',
	'CLAAG',
	'CLAAR',
	'CLAAT',
	'CLACK',
	'CLAEK',
	'CLAER',
	'CLAES',
	'CLAFF',
	'CLAFT',
	'CLAGG',
	'CLAIK',
	'CLAIP',
	'CLAIR',
	'CLAIS',
	'CLAKE',
	'CLAMP',
	'CLAMS',
	'CLANG',
	'CLANK',
	'CLAPE',
	'CLAPP',
	'CLAPS',
	'CLARE',
	'CLARK',
	'CLART',
	'CLARY',
	'CLASH',
	'CLASS',
	'CLATE',
	'CLATT',
	'CLATY',
	'CLAUD',
	'CLAUG',
	'CLAUM',
	'CLAUR',
	'CLAUT',
	'CLAWT',
	'CLAZE',
	'CLEAD',
	'CLEAK',
	'CLEAN',
	'CLEAR',
	'CLEAS',
	'CLECK',
	'CLEED',
	'CLEEK',
	'CLEEM',
	'CLEET',
	'CLEFF',
	'CLEFT',
	'CLEGG',
	'CLEID',
	'CLEIG',
	'CLEIK',
	'CLEIP',
	'CLEIT',
	'CLERK',
	'CLERT',
	'CLESH',
	'CLESP',
	'CLESS',
	'CLETT',
	'CLEUK',
	'CLEYS',
	'CLICK',
	'CLIEN',
	'CLIER',
	'CLIFF',
	'CLIFT',
	'CLIME',
	'CLIMP',
	'CLINE',
	'CLING',
	'CLINK',
	'CLINT',
	'CLIPE',
	'CLIPP',
	'CLIPS',
	'CLISH',
	'CLITE',
	'CLITT',
	'CLIVE',
	'CLOAK',
	'CLOAN',
	'CLOCH',
	'CLOCK',
	'CLODD',
	'CLOFF',
	'CLOFT',
	'CLOGE',
	'CLOGH',
	'CLOIT',
	'CLOKE',
	'CLOKS',
	'CLONK',
	'CLOOD',
	'CLOOF',
	'CLOOK',
	'CLOOP',
	'CLOOR',
	'CLOOT',
	'CLORT',
	'CLOSE',
	'CLOSS',
	'CLOTH',
	'CLOUD',
	'CLOUK',
	'CLOUP',
	'CLOUR',
	'CLOUT',
	'CLOVE',
	'CLOWE',
	'CLOWG',
	'CLOWK',
	'CLOYT',
	'CLUBE',
	'CLUCK',
	'CLUDS',
	'CLUFF',
	'CLUIF',
	'CLUIT',
	'CLUMP',
	'CLUNG',
	'CLUNK',
	'CLUPH',
	'CLURE',
	'CLURT',
	'CLUSE',
	'CLUSH',
	'CLUTE',
	'CLUTY',
	'CLYAK',
	'CLYDE',
	'CLYER',
	'CLYNE',
	'CLYPE',
	'CLYRE',
	'CLYTE',
	'COACH',
	'COALL',
	'COARN',
	'COASH',
	'COAST',
	'COATS',
	'COAXY',
	'COBBS',
	'COBLE',
	'COCKS',
	'CODLE',
	'COGLE',
	'COGUE',
	'COHOW',
	'COISH',
	'COIST',
	'COLIC',
	'COLLY',
	'COMBE',
	'COMIC',
	'COMMY',
	'COMPT',
	'CONDY',
	'COOCH',
	'COODY',
	'COOIE',
	'COOKE',
	'COOKY',
	'COOLK',
	'COOMB',
	'COONT',
	'COORD',
	'COORN',
	'COORT',
	'COORY',
	'COOSE',
	'COOST',
	'COOTH',
	'COOTY',
	'CORBY',
	'CORFT',
	'CORKY',
	'CORNE',
	'CORPE',
	'CORRI',
	'CORSE',
	'CORST',
	'CORVE',
	'COSEY',
	'COSIE',
	'COTCH',
	'COUCK',
	'COUDY',
	'COUFF',
	'COUMB',
	'COUPE',
	'COUPY',
	'COURD',
	'COUST',
	'COUTE',
	'COUTH',
	'COVEN',
	'COVER',
	'COVIN',
	'COVOY',
	'COWAN',
	'COWDA',
	'COWDY',
	'COWER',
	'COWIE',
	'COWLD',
	'COWPY',
	'COWSY',
	'COWTE',
	'CRACK',
	'CRAFT',
	'CRAGY',
	'CRAIB',
	'CRAIG',
	'CRAIK',
	'CRAIN',
	'CRAME',
	'CRAMP',
	'CRANE',
	'CRANG',
	'CRANK',
	'CRAPP',
	'CRASE',
	'CRASY',
	'CRAUK',
	'CRAVE',
	'CRAWL',
	'CRAWP',
	'CRAWS',
	'CRAZE',
	'CREAM',
	'CREAN',
	'CREED',
	'CREEK',
	'CREEL',
	'CREEN',
	'CREEP',
	'CREIL',
	'CRESS',
	'CREST',
	'CREUK',
	'CREYT',
	'CRIBB',
	'CRIFT',
	'CRIKE',
	'CRILE',
	'CRIMP',
	'CRINE',
	'CRING',
	'CRISP',
	'CROAG',
	'CROAK',
	'CROAM',
	'CROAN',
	'CROCE',
	'CROCH',
	'CROCK',
	'CROFT',
	'CROIL',
	'CROME',
	'CRONE',
	'CRONY',
	'CROOD',
	'CROOK',
	'CROOL',
	'CROON',
	'CROOP',
	'CROOT',
	'CROPT',
	'CROSE',
	'CROSS',
	'CROUD',
	'CROUL',
	'CROUP',
	'CROUS',
	'CROUT',
	'CROWL',
	'CROWN',
	'CROWP',
	'CROYD',
	'CROZE',
	'CRUBB',
	'CRUBE',
	'CRUCE',
	'CRUCK',
	'CRUDD',
	'CRUET',
	'CRUIK',
	'CRUIN',
	'CRUIT',
	'CRUKE',
	'CRULE',
	'CRULJ',
	'CRULL',
	'CRULT',
	'CRUMP',
	'CRUNT',
	'CRUSE',
	'CRYLE',
	'CUACK',
	'CUAIN',
	'CUBBY',
	'CUCOO',
	'CUDDY',
	'CUDGE',
	'CUDUM',
	'CUFIE',
	'CUIDD',
	'CUIST',
	'CUITH',
	'CULES',
	'CULLY',
	'CULSH',
	'CUNCH',
	'CUNDY',
	'CUNYO',
	'CUPAR',
	'CUPPO',
	'CURCH',
	'CURDS',
	'CURDY',
	'CURLY',
	'CURNE',
	'CURRA',
	'CURRY',
	'CURST',
	'CUSHA',
	'CUTER',
	'CUTLE',
	'CUTTY',
	'CUVIE',
	'CWEED',
	'CWEET',
	'CWINT',
	'CWITE',
	'CYAAK',
	'CYARD',
	'CYARL',
	'CYARN',
	'CYAUK',
	'CYOWT',
	'CYUCK',
	'DAACH',
	'DAART',
	'DABAL',
	'DACRE',
	'DADDY',
	'DADEL',
	'DADGE',
	'DADIE',
	'DADLE',
	'DAETH',
	'DAEZE',
	'DAGEN',
	'DAGRI',
	'DAICH',
	'DAIGH',
	'DAIRG',
	'DAIRK',
	'DAIRT',
	'DAISE',
	'DAISS',
	'DAISY',
	'DAITH',
	'DAIZE',
	'DAJON',
	'DAKER',
	'DAKIE',
	'DALLY',
	'DALVE',
	'DAMIS',
	'DAMPT',
	'DANCE',
	'DANDI',
	'DANDY',
	'DAPSE',
	'DARLE',
	'DARTH',
	'DASHY',
	'DASKE',
	'DATCH',
	'DAUCH',
	'DAUFT',
	'DAUGH',
	'DAULT',
	'DAUNT',
	'DAURG',
	'DAURK',
	'DAUTE',
	'DAVER',
	'DAVIT',
	'DAVVO',
	'DAWCH',
	'DAWGH',
	'DAWLY',
	'DAWRK',
	'DAZEN',
	'DEAFY',
	'DEALL',
	'DEARE',
	'DEARN',
	'DEART',
	'DEASE',
	'DEATH',
	'DEAVE',
	'DEAZE',
	'DEBAR',
	'DECAY',
	'DEDDY',
	'DEDLE',
	'DEEAL',
	'DEECE',
	'DEEDY',
	'DEEFY',
	'DEEST',
	'DEETH',
	'DEFAR',
	'DEICH',
	'DEIGH',
	'DEITH',
	'DEIVE',
	'DELET',
	'DELPH',
	'DELVE',
	'DEMAS',
	'DEMIT',
	'DENER',
	'DERAY',
	'DERLE',
	'DERRY',
	'DEUCH',
	'DEUGH',
	'DEVAL',
	'DEVAN',
	'DEVEL',
	'DEVER',
	'DEVON',
	'DEWAR',
	'DEYTH',
	'DICHT',
	'DICKO',
	'DICKY',
	'DIDER',
	'DIDGY',
	'DIDLE',
	'DIFFY',
	'DIGHT',
	'DILLY',
	'DILSE',
	'DIMIT',
	'DINGE',
	'DINKY',
	'DINLE',
	'DINTY',
	'DIRGY',
	'DIRRY',
	'DIRTY',
	'DITTY',
	'DIVAN',
	'DIVER',
	'DIVES',
	'DIVIL',
	'DIVOT',
	'DIVSO',
	'DIXIE',
	'DIZEN',
	'DJARF',
	'DOACH',
	'DOAGH',
	'DOBIE',
	'DOCAS',
	'DOCKY',
	'DOCUS',
	'DODDY',
	'DODGE',
	'DODLE',
	'DOFEN',
	'DOGER',
	'DOILT',
	'DOIST',
	'DOLEK',
	'DOLLY',
	'DOMBA',
	'DOMRA',
	'DONCE',
	'DONEY',
	'DONIE',
	'DONSY',
	'DOOCK',
	'DOOMS',
	'DOONS',
	'DOORT',
	'DOOSE',
	'DOOST',
	'DOOTH',
	'DOOZY',
	'DORBY',
	'DOREN',
	'DORES',
	'DORIC',
	'DORIE',
	'DORIN',
	'DORLE',
	'DORRO',
	'DORTY',
	'DOSAN',
	'DOTCH',
	'DOUCE',
	'DOUGE',
	'DOUGH',
	'DOUSE',
	'DOUTH',
	'DOVEN',
	'DOVER',
	'DOVIE',
	'DOWFF',
	'DOWIE',
	'DOWLY',
	'DOWNS',
	'DOWSE',
	'DOWTH',
	'DOWZY',
	'DOXIE',
	'DOZEN',
	'DOZET',
	'DOZIE',
	'DRAAG',
	'DRAAM',
	'DRAFF',
	'DRAIG',
	'DRAIK',
	'DRAIL',
	'DRAME',
	'DRAMM',
	'DRANE',
	'DRANG',
	'DRAVE',
	'DRECH',
	'DREED',
	'DREEF',
	'DREEL',
	'DREEP',
	'DREID',
	'DRENG',
	'DRESS',
	'DREVL',
	'DRIFT',
	'DRILL',
	'DRILT',
	'DRING',
	'DRINK',
	'DRITE',
	'DRIVE',
	'DRIVL',
	'DROCH',
	'DROCK',
	'DRODS',
	'DROGY',
	'DROKE',
	'DROLL',
	'DRONE',
	'DRONG',
	'DRONY',
	'DROOG',
	'DROOK',
	'DROOL',
	'DROON',
	'DROOP',
	'DROSS',
	'DROUD',
	'DROUK',
	'DROUL',
	'DROUN',
	'DROVE',
	'DROWD',
	'DROZE',
	'DRUGG',
	'DRULE',
	'DRULT',
	'DRULY',
	'DRUNE',
	'DRUNT',
	'DRURI',
	'DRURY',
	'DRUSH',
	'DRUTE',
	'DUCHY',
	'DUDDS',
	'DUDDY',
	'DUGON',
	'DUINK',
	'DUIST',
	'DUIVO',
	'DUKKI',
	'DULAP',
	'DULSE',
	'DUMBA',
	'DUMPH',
	'DUMPS',
	'DUMPY',
	'DUNCE',
	'DUNCH',
	'DUNDY',
	'DUNGL',
	'DUNNY',
	'DUNSH',
	'DUNTY',
	'DUPLY',
	'DUSSO',
	'DUSTY',
	'DUTCH',
	'DUTHE',
	'DWALL',
	'DWANG',
	'DWARG',
	'DWARS',
	'DWINE',
	'DYANG',
	'DYAUN',
	'DYBAL',
	'DYCAL',
	'DYDIE',
	'DYFER',
	'DYING',
	'DYKER',
	'DYKIE',
	'DYOCH',
	'DYPER',
	'DYRIE',
	'EALIE',
	'EARTH',
	'EASER',
	'EASIN',
	'EASLE',
	'EATEM',
	'EATEN',
	'EAZLE',
	'EBBIT',
	'ECCLE',
	'ECHAN',
	'ECKEN',
	'EDDER',
	'EDDLE',
	'EDGAR',
	'EDGIE',
	'EDGIT',
	'EDICT',
	'EDYAN',
	'EEBLE',
	'EEDOL',
	'EEKIL',
	'EELAN',
	'EELAT',
	'EELIE',
	'EEMIR',
	'EENCE',
	'EENDY',
	'EENIE',
	'EENIL',
	'EENOO',
	'EENTY',
	'EERAM',
	'EERIE',
	'EERIF',
	'EESAC',
	'EETCH',
	'EETIM',
	'EETLE',
	'EEVIE',
	'EFFIE',
	'EFTER',
	'EGGLE',
	'EGYPT',
	'EICHT',
	'EISEN',
	'EIZEL',
	'ELDER',
	'ELDIN',
	'ELECK',
	'ELFAN',
	'ELFIN',
	'ELICK',
	'ELIDE',
	'ELLER',
	'EMBER',
	'EMBRA',
	'EMBRO',
	'EMPIE',
	'ENACT',
	'ENARM',
	'ENDIE',
	'ENDOO',
	'ENDUE',
	'ENEMY',
	'ENICH',
	'ENNER',
	'ENNET',
	'ENNIS',
	'ENORM',
	'ENTER',
	'ENTRY',
	'ENZIE',
	'EPHOR',
	'EPPIE',
	'EPPLE',
	'ERICK',
	'ERLED',
	'ERLES',
	'ERMIT',
	'ERNIT',
	'ERRLE',
	'ERSIT',
	'ESRAL',
	'ESSEL',
	'ESTIT',
	'ETHER',
	'ETICK',
	'ETION',
	'ETTER',
	'ETTLE',
	'EVANS',
	'EVERT',
	'EVERY',
	'EVITE',
	'EVRAN',
	'EVRIE',
	'EWEST',
	'EWHOW',
	'EXACK',
	'EXEEM',
	'EXEMP',
	'EXIES',
	'EXITE',
	'EYNIE',
	'EYZLE',
	'EZLAR',
	'FACIE',
	'FACIN',
	'FADER',
	'FADGE',
	'FAIGH',
	'FAINT',
	'FAIRD',
	'FAIRY',
	'FAIZE',
	'FALSE',
	'FANGS',
	'FARAN',
	'FARCE',
	'FARIN',
	'FARLE',
	'FATCH',
	'FAUCH',
	'FAUGH',
	'FAULD',
	'FAUSE',
	'FEARN',
	'FEART',
	'FEAST',
	'FECHT',
	'FEECH',
	'FEENK',
	'FEERD',
	'FEESK',
	'FEESP',
	'FEETH',
	'FEEVL',
	'FEEZE',
	'FEICE',
	'FEIST',
	'FEITH',
	'FELON',
	'FEMLE',
	'FENCE',
	'FERDY',
	'FERGE',
	'FERRY',
	'FERST',
	'FEUAR',
	'FEUCH',
	'FIALL',
	'FIDDL',
	'FIDGE',
	'FIDRO',
	'FIELD',
	'FIENT',
	'FIERY',
	'FIETH',
	'FIFER',
	'FIFIE',
	'FIKEK',
	'FILES',
	'FILSH',
	'FILTY',
	'FIMIS',
	'FINNA',
	'FIOLD',
	'FIRRY',
	'FIRST',
	'FIRTH',
	'FISHO',
	'FISTY',
	'FITBA',
	'FITCH',
	'FITEN',
	'FITIN',
	'FITSH',
	'FIVER',
	'FIVES',
	'FJAAG',
	'FJARM',
	'FJORA',
	'FLAAD',
	'FLACK',
	'FLAFF',
	'FLAIK',
	'FLAIL',
	'FLAIP',
	'FLAIR',
	'FLAKE',
	'FLAMB',
	'FLAMP',
	'FLANE',
	'FLANK',
	'FLARY',
	'FLASH',
	'FLAST',
	'FLATE',
	'FLAUD',
	'FLAUR',
	'FLECH',
	'FLECK',
	'FLEED',
	'FLEEM',
	'FLEEP',
	'FLEER',
	'FLEET',
	'FLEME',
	'FLENK',
	'FLESH',
	'FLEUK',
	'FLEUP',
	'FLICK',
	'FLIET',
	'FLING',
	'FLINK',
	'FLIRD',
	'FLIRN',
	'FLIRR',
	'FLISK',
	'FLISS',
	'FLIST',
	'FLIUG',
	'FLOAN',
	'FLOAT',
	'FLOCK',
	'FLORY',
	'FLOSK',
	'FLOSS',
	'FLOUR',
	'FLOYT',
	'FLUDE',
	'FLUFF',
	'FLUIR',
	'FLUKE',
	'FLUSH',
	'FLUST',
	'FLYFE',
	'FLYPE',
	'FLYRE',
	'FLYTE',
	'FOARD',
	'FOCHT',
	'FODGE',
	'FOLLY',
	'FOOCH',
	'FOOSH',
	'FOOSK',
	'FOOST',
	'FORBY',
	'FORCA',
	'FORCE',
	'FORRA',
	'FORTY',
	'FOTCH',
	'FOUAT',
	'FOUKY',
	'FOUND',
	'FOURT',
	'FOUTH',
	'FOWER',
	'FOZIE',
	'FOZLE',
	'FRACA',
	'FRAIK',
	'FRAIL',
	'FRAME',
	'FRANK',
	'FRATH',
	'FRAYN',
	'FRECK',
	'FREEL',
	'FREER',
	'FREET',
	'FREFF',
	'FREIT',
	'FREMD',
	'FRENN',
	'FRESH',
	'FRIAR',
	'FRIST',
	'FRIZZ',
	'FROAD',
	'FROCK',
	'FROGE',
	'FRONE',
	'FRONT',
	'FROON',
	'FROST',
	'FRUIT',
	'FRULL',
	'FRUMP',
	'FRUSH',
	'FRYNE',
	'FUFTY',
	'FUGIE',
	'FUHRE',
	'FUICH',
	'FUIRD',
	'FUMIE',
	'FUNCY',
	'FUNDY',
	'FUNKO',
	'FUNNA',
	'FUNNY',
	'FURTH',
	'FURTO',
	'FURVO',
	'FUSEE',
	'FUSHT',
	'FUSSY',
	'FUTCH',
	'FUZET',
	'FYARM',
	'FYFTY',
	'FYKIE',
	'FYOAG',
	'FYOLE',
	'FYOWN',
	'FYTAL',
	'FYTEN',
	'GAADY',
	'GAALO',
	'GAASE',
	'GAAVE',
	'GABAL',
	'GABER',
	'GABLO',
	'GADGE',
	'GAIRD',
	'GAIRN',
	'GAIRY',
	'GAISE',
	'GAIVE',
	'GAKIE',
	'GALAT',
	'GALSH',
	'GALTI',
	'GAMIE',
	'GAMSE',
	'GANDY',
	'GANGE',
	'GANGS',
	'GANSH',
	'GAPPY',
	'GARDY',
	'GARSE',
	'GARTH',
	'GATTY',
	'GAUDY',
	'GAUGE',
	'GAUND',
	'GAVAL',
	'GAVEL',
	'GAVIL',
	'GAVIN',
	'GEACH',
	'GEDDY',
	'GEESE',
	'GEICH',
	'GEING',
	'GEMAN',
	'GENER',
	'GENIE',
	'GENUS',
	'GEOTT',
	'GEYZE',
	'GHOST',
	'GHOUL',
	'GIBBY',
	'GIGOT',
	'GILES',
	'GILET',
	'GILSE',
	'GIMSE',
	'GINCH',
	'GINGE',
	'GIPSY',
	'GIRBI',
	'GIRSE',
	'GIRST',
	'GIRTH',
	'GITEL',
	'GITTY',
	'GIVEN',
	'GIZZY',
	'GLAAB',
	'GLAAM',
	'GLAAN',
	'GLACK',
	'GLAFF',
	'GLAIK',
	'GLAIM',
	'GLAIP',
	'GLAMP',
	'GLAPE',
	'GLASP',
	'GLASS',
	'GLAUD',
	'GLAUM',
	'GLAUN',
	'GLAUP',
	'GLAUR',
	'GLAZE',
	'GLEBE',
	'GLEDE',
	'GLEED',
	'GLEEK',
	'GLEER',
	'GLEET',
	'GLEIB',
	'GLEIG',
	'GLENT',
	'GLESS',
	'GLIBE',
	'GLIBS',
	'GLIFF',
	'GLIKE',
	'GLIME',
	'GLIMP',
	'GLINK',
	'GLINT',
	'GLIPE',
	'GLISK',
	'GLISS',
	'GLIST',
	'GLOAM',
	'GLOAN',
	'GLOCK',
	'GLOFF',
	'GLOID',
	'GLOIT',
	'GLONE',
	'GLONK',
	'GLOOE',
	'GLOOM',
	'GLOOR',
	'GLORE',
	'GLORG',
	'GLORY',
	'GLOSE',
	'GLOSS',
	'GLOTT',
	'GLOUP',
	'GLOUT',
	'GLOWE',
	'GLOWN',
	'GLOZE',
	'GLUCK',
	'GLUDE',
	'GLUFF',
	'GLUGG',
	'GLUMP',
	'GLUNK',
	'GLUNT',
	'GLUSH',
	'GLYDE',
	'GNARL',
	'GNASH',
	'GNECK',
	'GNEUT',
	'GOCKS',
	'GODIN',
	'GOGAR',
	'GOGIE',
	'GOICH',
	'GOIEK',
	'GOILC',
	'GOILK',
	'GOIND',
	'GOLDY',
	'GOLLA',
	'GOLTI',
	'GOMUP',
	'GOOLD',
	'GORDS',
	'GORFY',
	'GORGE',
	'GOSEN',
	'GOTCH',
	'GOUCH',
	'GOULS',
	'GOURD',
	'GOVIE',
	'GOVIT',
	'GOVUS',
	'GOWAN',
	'GOWDS',
	'GOWLD',
	'GOWST',
	'GOZEN',
	'GRAAT',
	'GRACE',
	'GRACH',
	'GRAFF',
	'GRAFT',
	'GRAIG',
	'GRAIN',
	'GRAIP',
	'GRAND',
	'GRATE',
	'GRAUD',
	'GRAVE',
	'GRAVY',
	'GRAWL',
	'GREAN',
	'GREAT',
	'GREED',
	'GREEK',
	'GREEM',
	'GREEN',
	'GREET',
	'GRESS',
	'GRICE',
	'GRIEF',
	'GRIEM',
	'GRILD',
	'GRILL',
	'GRIMA',
	'GRIME',
	'GRIND',
	'GRINK',
	'GRIPE',
	'GRISK',
	'GRIST',
	'GROAT',
	'GROBY',
	'GROFF',
	'GROIN',
	'GROND',
	'GRONE',
	'GRONO',
	'GROOB',
	'GROOF',
	'GROOL',
	'GROPE',
	'GROTT',
	'GROWK',
	'GROWP',
	'GROZE',
	'GRUEL',
	'GRUGG',
	'GRUGS',
	'GRUIP',
	'GRUMP',
	'GRUND',
	'GRUNI',
	'GRUNK',
	'GRUNT',
	'GRUSE',
	'GRUSH',
	'GRUTE',
	'GRÜVI',
	'GRYCH',
	'GRYFE',
	'GUDGE',
	'GÜDIE',
	'GUESS',
	'GUEST',
	'GUIDE',
	'GUILD',
	'GUILT',
	'GUIRT',
	'GUISE',
	'GUISS',
	'GULCH',
	'GULLA',
	'GULLY',
	'GULPH',
	'GULSA',
	'GULSH',
	'GUMPH',
	'GUMSH',
	'GUNCH',
	'GUNDY',
	'GURDY',
	'GURGE',
	'GURGY',
	'GURRY',
	'GURTH',
	'GUSEL',
	'GUTTA',
	'GWICK',
	'GYOLA',
	'GYPER',
	'GYPIT',
	'GYRIE',
	'GYTER',
	'GYURD',
	'HAAIN',
	'HABBY',
	'HABEN',
	'HABIT',
	'HAFTY',
	'HAGRA',
	'HAGRI',
	'HAICH',
	'HAIRM',
	'HAIRP',
	'HAIRT',
	'HAIRY',
	'HAITH',
	'HAIZE',
	'HALGH',
	'HALIE',
	'HALIN',
	'HALTH',
	'HALVE',
	'HAMIE',
	'HAMIT',
	'HAMSY',
	'HANCH',
	'HANDY',
	'HANIN',
	'HANSI',
	'HANTY',
	'HAPPY',
	'HARDS',
	'HARDY',
	'HARKY',
	'HARLE',
	'HARLY',
	'HARPI',
	'HARRO',
	'HARRY',
	'HASAN',
	'HASKY',
	'HASTY',
	'HATCH',
	'HAUCE',
	'HAUCH',
	'HAUGH',
	'HAUNT',
	'HAUSE',
	'HAVER',
	'HAYII',
	'HAZEL',
	'HEALE',
	'HEARY',
	'HEAVE',
	'HEAVY',
	'HECHT',
	'HECKO',
	'HEDGE',
	'HEECH',
	'HEELD',
	'HEERE',
	'HEERY',
	'HEEST',
	'HEEZE',
	'HEGHT',
	'HEICH',
	'HEISK',
	'HEIST',
	'HEKLA',
	'HELLY',
	'HELMA',
	'HELSE',
	'HEMMA',
	'HENKS',
	'HENOU',
	'HERDA',
	'HERIE',
	'HERLE',
	'HERSH',
	'HERTO',
	'HERVY',
	'HEUCH',
	'HEVEL',
	'HEYRD',
	'HEYRT',
	'HIDIE',
	'HILCH',
	'HIMPS',
	'HIMST',
	'HIRST',
	'HITCH',
	'HIVES',
	'HIVIE',
	'HOAST',
	'HOBBY',
	'HOCUS',
	'HODGE',
	'HOIGH',
	'HOISE',
	'HOKEY',
	'HOLIE',
	'HOOCH',
	'HOOIE',
	'HOOIT',
	'HOOKY',
	'HOOPS',
	'HOOSE',
	'HOPEA',
	'HORIN',
	'HORNA',
	'HORSE',
	'HORSO',
	'HOTCH',
	'HOTTY',
	'HOULD',
	'HOULL',
	'HOUSE',
	'HOVER',
	'HOWFF',
	'HOWIE',
	'HOWLD',
	'HOWSY',
	'HOYES',
	'HUDGE',
	'HÜDIK',
	'HUILK',
	'HUIRD',
	'HÜIST',
	'HUIVE',
	'HULLA',
	'HUMCH',
	'HUMPH',
	'HUNCH',
	'HUNSE',
	'HUPPO',
	'HURLY',
	'HURRY',
	'HUSHO',
	'HUSKY',
	'HUSTA',
	'HUTCH',
	'HUTER',
	'HUVIE',
	'HWALP',
	'HWAMP',
	'HWARM',
	'HWEMM',
	'HWERN',
	'HWIDA',
	'HWIFF',
	'HWIKK',
	'HWINI',
	'HYFIE',
	'HYPAL',
	'HYPED',
	'HYTER',
	'HYTHE',
	'IBBIE',
	'ICKER',
	'IDAIA',
	'IEROE',
	'ILDER',
	'ILLIE',
	'IMANG',
	'IMMEN',
	'IMMER',
	'IMMIS',
	'IMOST',
	'IMPER',
	'IMPHM',
	'IMPTY',
	'INACT',
	'INAWN',
	'INCEP',
	'INDIE',
	'INFER',
	'INFIT',
	'INGAN',
	'INGIE',
	'INGLE',
	'INKER',
	'INKLE',
	'INLAT',
	'INLAY',
	'INLER',
	'INNER',
	'INNIN',
	'INNIS',
	'INPIT',
	'INROW',
	'INSEE',
	'INSET',
	'INTAE',
	'INTAK',
	'INTER',
	'INTIL',
	'INVER',
	'INWAN',
	'IRISH',
	'ISELL',
	'ITHER',
	'ITSEL',
	'IVERY',
	'IVORY',
	'IVVER',
	'IZZAT',
	'JACEY',
	'JACOB',
	'JACOD',
	'JAKES',
	'JAKEY',
	'JAMIE',
	'JAMPH',
	'JANET',
	'JANGO',
	'JARRY',
	'JAUNT',
	'JAVEL',
	'JAWER',
	'JEDGE',
	'JEEMS',
	'JEEST',
	'JEEZO',
	'JETEE',
	'JEVEL',
	'JIMMY',
	'JINGS',
	'JINKY',
	'JINSE',
	'JINSH',
	'JIRRY',
	'JOICE',
	'JOINT',
	'JOKUL',
	'JOKUS',
	'JORAM',
	'JORUM',
	'JOSEY',
	'JOUGS',
	'JOVIS',
	'JOWDY',
	'JOWEL',
	'JOYSE',
	'JUDEN',
	'JUDGE',
	'JUICE',
	'JUIST',
	'JULIE',
	'JUMBO',
	'JUMMY',
	'JUNKY',
	'JUROR',
	'JYPLE',
	'KABBI',
	'KABBY',
	'KABER',
	'KADDI',
	'KADDY',
	'KADEL',
	'KADGY',
	'KAERD',
	'KAEVE',
	'KAEVY',
	'KAIRD',
	'KAIRN',
	'KAIRS',
	'KAIRT',
	'KAIRY',
	'KAIVE',
	'KAIVY',
	'KAKER',
	'KALLO',
	'KALLU',
	'KALOO',
	'KANGL',
	'KANNA',
	'KANNI',
	'KANNY',
	'KANTY',
	'KAPER',
	'KAPPI',
	'KARVI',
	'KATER',
	'KATHY',
	'KATIE',
	'KATOO',
	'KATTY',
	'KAUCH',
	'KAVEL',
	'KAVER',
	'KAVIE',
	'KAVIL',
	'KEARD',
	'KEAVE',
	'KEBAG',
	'KEBAR',
	'KECHT',
	'KEDGE',
	'KEDGY',
	'KEECH',
	'KEELY',
	'KEENG',
	'KEEOW',
	'KEERS',
	'KEESO',
	'KEEST',
	'KEGEL',
	'KEIST',
	'KEITH',
	'KELDI',
	'KELLY',
	'KELSO',
	'KELVA',
	'KEOCH',
	'KERRO',
	'KERRY',
	'KERSE',
	'KETCH',
	'KEUCH',
	'KEUDE',
	'KEUFF',
	'KEULT',
	'KEULY',
	'KEVEL',
	'KEVER',
	'KEVIE',
	'KEVIL',
	'KIBER',
	'KIDDY',
	'KIERV',
	'KIEST',
	'KIEVE',
	'KIFFY',
	'KILCH',
	'KILER',
	'KILES',
	'KILIE',
	'KILTY',
	'KILYA',
	'KIMEN',
	'KINCH',
	'KINGI',
	'KINSH',
	'KIRBY',
	'KIRCH',
	'KIVAL',
	'KIVEN',
	'KIVER',
	'KIVIN',
	'KIVVY',
	'KIZEN',
	'KJIRP',
	'KLAAG',
	'KLAAT',
	'KLAIK',
	'KLAIR',
	'KLAMP',
	'KLANG',
	'KLANK',
	'KLART',
	'KLASH',
	'KLAVE',
	'KLAVO',
	'KLECK',
	'KLEEK',
	'KLEIP',
	'KLEPP',
	'KLETT',
	'KLIEK',
	'KLIER',
	'KLIFT',
	'KLIME',
	'KLINE',
	'KLING',
	'KLINK',
	'KLINT',
	'KLIPE',
	'KLOIT',
	'KLOKK',
	'KLOKS',
	'KLONT',
	'KLOOK',
	'KLOOR',
	'KLOOT',
	'KLOSS',
	'KLUIR',
	'KLUMS',
	'KLUNG',
	'KLUNK',
	'KLURT',
	'KLUSH',
	'KLYPE',
	'KLYRE',
	'KLYTE',
	'KNACK',
	'KNARK',
	'KNARP',
	'KNASH',
	'KNAVE',
	'KNEED',
	'KNEEF',
	'KNEEP',
	'KNELL',
	'KNICK',
	'KNIFE',
	'KNIFF',
	'KNOCK',
	'KNOIT',
	'KNOLL',
	'KNOLT',
	'KNOOL',
	'KNORE',
	'KNOUD',
	'KNOUP',
	'KNUCK',
	'KNULE',
	'KNURL',
	'KNUSE',
	'KNYPE',
	'KOBOS',
	'KODDI',
	'KOLLI',
	'KOLLY',
	'KOMIN',
	'KOMON',
	'KOOKS',
	'KOOMY',
	'KORBI',
	'KORSE',
	'KRACK',
	'KRAEG',
	'KRAIG',
	'KRAIK',
	'KRANE',
	'KRANK',
	'KRASS',
	'KREEL',
	'KREKS',
	'KREPP',
	'KRETS',
	'KRIMM',
	'KRIMP',
	'KRING',
	'KRINT',
	'KROBB',
	'KROLK',
	'KROME',
	'KROMO',
	'KROOK',
	'KROOT',
	'KROVE',
	'KRUIK',
	'KRUMP',
	'KRUNE',
	'KRUTE',
	'KUBBY',
	'KUDDI',
	'KUFFY',
	'KUIVY',
	'KUJLI',
	'KUNNO',
	'KUNYO',
	'KUPPO',
	'KURCH',
	'KURLO',
	'KUSSI',
	'KÜTEL',
	'KUTER',
	'KUTTY',
	'KWARD',
	'KWARR',
	'KWEED',
	'KWERK',
	'KWIRN',
	'KWITE',
	'KWYTE',
	'KYAAK',
	'KYARD',
	'KYEUK',
	'KYLOE',
	'KYOAB',
	'KYOLT',
	'KYRIE',
	'KYTHE',
	'LABEL',
	'LABIE',
	'LACER',
	'LADLE',
	'LAEGA',
	'LAFFY',
	'LAFTY',
	'LAGET',
	'LAHAN',
	'LAICH',
	'LAIGH',
	'LAING',
	'LAIRD',
	'LAIRG',
	'LAISH',
	'LAIST',
	'LAITH',
	'LALDY',
	'LAMER',
	'LANCE',
	'LANNY',
	'LANTA',
	'LAPPY',
	'LATCH',
	'LATIN',
	'LAUCH',
	'LAVIE',
	'LAWER',
	'LAWIN',
	'LEAFU',
	'LEARN',
	'LEASE',
	'LEASH',
	'LEASS',
	'LEAST',
	'LEAVE',
	'LEDGE',
	'LEECH',
	'LEEFU',
	'LEENO',
	'LEEPS',
	'LEESE',
	'LEEVE',
	'LEFTY',
	'LEGAL',
	'LEILL',
	'LEISH',
	'LEMSE',
	'LENCH',
	'LENNO',
	'LENTH',
	'LEPER',
	'LEPPY',
	'LETTO',
	'LEURE',
	'LEVEN',
	'LEWER',
	'LIBEL',
	'LICHT',
	'LIGNY',
	'LIKEN',
	'LILLY',
	'LIMER',
	'LIMRO',
	'LINCH',
	'LINEN',
	'LINKS',
	'LIOOM',
	'LIORA',
	'LITCH',
	'LITHE',
	'LITHY',
	'LIUNG',
	'LIVER',
	'LIXIE',
	'LOAMY',
	'LOBAN',
	'LOCAL',
	'LOCUS',
	'LODER',
	'LODGE',
	'LODIE',
	'LOGAN',
	'LOGIE',
	'LONGE',
	'LONYO',
	'LOONG',
	'LORIE',
	'LORIN',
	'LORNE',
	'LORRY',
	'LORUM',
	'LOSEL',
	'LOTCH',
	'LOUCH',
	'LOUGH',
	'LOUSE',
	'LOUTH',
	'LOUTS',
	'LOWER',
	'LOWIE',
	'LOWRA',
	'LOWSE',
	'LOZEN',
	'LUBBA',
	'LUBIT',
	'LUCKY',
	'LUNCH',
	'LUNGE',
	'LURRY',
	'LYACH',
	'LYAGE',
	'LYALL',
	'LYART',
	'LYELL',
	'LYNTH',
	'LYRIE',
	'LYTER',
	'LYTHE',
	'MACER',
	'MADAM',
	'MADGE',
	'MADIE',
	'MAGER',
	'MAGIN',
	'MAINS',
	'MAISE',
	'MAISK',
	'MAIST',
	'MAJIE',
	'MAJOR',
	'MAKAR',
	'MAMIE',
	'MANGS',
	'MANNO',
	'MANSE',
	'MARAG',
	'MARCH',
	'MAREE',
	'MARIE',
	'MARLE',
	'MASON',
	'MATCH',
	'MATED',
	'MATLO',
	'MAUSE',
	'MAVIS',
	'MAXIE',
	'MAYBE',
	'MAZLE',
	'MEASE',
	'MEDAL',
	'MEDIE',
	'MEESE',
	'MEETH',
	'MEEVE',
	'MEGIN',
	'MEITH',
	'MELDY',
	'MELGS',
	'MELLY',
	'MENDS',
	'MENIE',
	'MENSE',
	'MENTO',
	'MERCY',
	'MERGH',
	'MERIT',
	'MERRY',
	'MERSE',
	'MERSK',
	'MERVY',
	'METAL',
	'MEXIE',
	'MICHT',
	'MIDGE',
	'MIESE',
	'MIKIE',
	'MILDO',
	'MILNE',
	'MINCE',
	'MINCH',
	'MINER',
	'MINIE',
	'MINNY',
	'MINOD',
	'MINOR',
	'MISCA',
	'MISDO',
	'MISFA',
	'MITCH',
	'MITLE',
	'MODEL',
	'MOGER',
	'MOICH',
	'MOIND',
	'MOKIE',
	'MOMIE',
	'MONEN',
	'MONEY',
	'MONIE',
	'MONTH',
	'MOOAG',
	'MOOCH',
	'MOOLY',
	'MOORK',
	'MOOSH',
	'MOOSK',
	'MOOST',
	'MOOTH',
	'MORAY',
	'MORED',
	'MOROI',
	'MOTCH',
	'MOUCH',
	'MOUIE',
	'MOUSE',
	'MOUTH',
	'MOWAT',
	'MOWSY',
	'MOYEN',
	'MOZEY',
	'MOZIE',
	'MUDGE',
	'MUDIE',
	'MUILD',
	'MUIND',
	'MUIST',
	'MUITH',
	'MUITT',
	'MÜLDO',
	'MULIE',
	'MULTI',
	'MUNDY',
	'MUNGA',
	'MUNGE',
	'MUNGO',
	'MUNRO',
	'MUNYO',
	'MURIE',
	'MURLE',
	'MURLY',
	'MUSIC',
	'MUSIE',
	'MUSKY',
	'MUSSA',
	'MUTCH',
	'MYAAT',
	'MYAVE',
	'MYLES',
	'MYOWT',
	'MYSEL',
	'MYTIE',
	'MYXIE',
	'NABAL',
	'NABER',
	'NAHVY',
	'NAKIT',
	'NAMER',
	'NANES',
	'NANNY',
	'NANSE',
	'NANSY',
	'NANTY',
	'NAPPA',
	'NATCH',
	'NAVEL',
	'NAVER',
	'NAVIE',
	'NEATH',
	'NEESE',
	'NEIST',
	'NENST',
	'NEPPS',
	'NEVEL',
	'NEVER',
	'NEVOY',
	'NICHT',
	'NICKS',
	'NICOL',
	'NIDGE',
	'NIESH',
	'NIEVE',
	'NIMSH',
	'NINKI',
	'NITCH',
	'NIVED',
	'NOBLE',
	'NOCHT',
	'NODDY',
	'NODGE',
	'NODIE',
	'NOISE',
	'NOKKU',
	'NOOFY',
	'NORIE',
	'NORSE',
	'NORTH',
	'NOSIT',
	'NOTAR',
	'NOTCH',
	'NOUST',
	'NOYNT',
	'NUDGE',
	'NUIST',
	'NULLS',
	'NULLY',
	'NULSH',
	'NUMPY',
	'NURSE',
	'NYAFF',
	'NYAPH',
	'NYARB',
	'NYARM',
	'NYOWL',
	'OBDER',
	'OCHIL',
	'OCHRA',
	'OCKER',
	'ODDER',
	'ODDLE',
	'OFFEN',
	'OFFER',
	'OFFIE',
	'OGLET',
	'OGRIE',
	'OISIE',
	'OLGER',
	'OLITE',
	'OLLAG',
	'OLLER',
	'ONDAG',
	'ONGAE',
	'ONLAT',
	'ONLAY',
	'ONPIT',
	'ONSET',
	'ONSLA',
	'ONTAE',
	'ONTAK',
	'ONWAL',
	'OOBIT',
	'OOGIS',
	'OOGLY',
	'OOLER',
	'OOLET',
	'OOMIT',
	'OORIE',
	'OOSEL',
	'OOTHY',
	'OOVAS',
	'OOYAH',
	'OPERY',
	'ORAFU',
	'ORDER',
	'ORKIE',
	'ORMAL',
	'ORPIE',
	'ORRAL',
	'ORRET',
	'ORTER',
	'OSHEN',
	'OSHIE',
	'OSLIN',
	'OSMAL',
	'OSSAN',
	'OSSIE',
	'OSSIT',
	'OSSKA',
	'OTHER',
	'OTTER',
	'OUGHT',
	'OUTBY',
	'OUTCA',
	'OUTEN',
	'OUTER',
	'OUTFA',
	'OUTGO',
	'OUTLY',
	'OUZEL',
	'OVERY',
	'OWDIE',
	'OWDNY',
	'OWRIE',
	'OXTER',
	'OYLEN',
	'PACES',
	'PADDY',
	'PAGEA',
	'PAILL',
	'PAINT',
	'PAIRT',
	'PAISE',
	'PALCH',
	'PALIE',
	'PANEL',
	'PANSE',
	'PAPER',
	'PARAN',
	'PARCH',
	'PARIS',
	'PARRY',
	'PASTY',
	'PATIE',
	'PATTY',
	'PAUCE',
	'PAUGE',
	'PAULE',
	'PAVER',
	'PAVIE',
	'PEACE',
	'PEARL',
	'PEASE',
	'PECHT',
	'PECHY',
	'PEEOY',
	'PEETY',
	'PEEVE',
	'PEIST',
	'PELEK',
	'PELOO',
	'PELSH',
	'PENGA',
	'PENNA',
	'PENNY',
	'PENSE',
	'PENTY',
	'PETER',
	'PETTY',
	'PEUCH',
	'PIANO',
	'PIECE',
	'PIEGE',
	'PIEST',
	'PILLY',
	'PILSH',
	'PINCH',
	'PINER',
	'PINGO',
	'PINIT',
	'PINNI',
	'PINNO',
	'PINTO',
	'PIPER',
	'PITCH',
	'PIVER',
	'PIZAN',
	'PIZOO',
	'PLACE',
	'PLACK',
	'PLAID',
	'PLAIK',
	'PLAIN',
	'PLAIP',
	'PLANE',
	'PLANK',
	'PLANT',
	'PLASH',
	'PLATE',
	'PLATT',
	'PLEAD',
	'PLEAT',
	'PLEEN',
	'PLEEP',
	'PLERT',
	'PLESK',
	'PLINK',
	'PLOCK',
	'PLONK',
	'PLOOK',
	'PLOOM',
	'PLORE',
	'PLOUD',
	'PLOUK',
	'PLOWD',
	'PLOWP',
	'PLOWT',
	'PLUCK',
	'PLUFF',
	'PLUIT',
	'PLUKK',
	'PLUMB',
	'PLUMP',
	'PLUNK',
	'PLURR',
	'PLUSH',
	'PLYPE',
	'POACH',
	'POBIE',
	'PODGE',
	'POIND',
	'POINT',
	'POKER',
	'POLIS',
	'POLTY',
	'POOER',
	'POOIT',
	'POOKY',
	'POOSK',
	'PORPY',
	'POTCH',
	'POUCE',
	'POUCH',
	'POUFF',
	'POUKY',
	'POUND',
	'POUSS',
	'POUST',
	'POVIE',
	'POWAN',
	'POWST',
	'POYET',
	'POZIE',
	'PRAIN',
	'PRANG',
	'PRANK',
	'PREEN',
	'PRENT',
	'PRESS',
	'PRICE',
	'PRICK',
	'PRIDE',
	'PRILE',
	'PRIME',
	'PRIMP',
	'PRINK',
	'PRISE',
	'PRIVY',
	'PRIZE',
	'PROIL',
	'PROKE',
	'PROLL',
	'PRONT',
	'PROOP',
	'PROSS',
	'PROUD',
	'PRUCH',
	'PRUIF',
	'PRUTE',
	'PSALM',
	'PUDDY',
	'PUDGE',
	'PUGSO',
	'PUILO',
	'PUIPP',
	'PUIST',
	'PUNCE',
	'PUNCH',
	'PUPIL',
	'PUPIT',
	'PURGE',
	'PURGY',
	'PURRY',
	'PURSE',
	'PUTTY',
	'PYOCH',
	'PYRRE',
	'QUACK',
	'QUAIR',
	'QUALL',
	'QUALM',
	'QUART',
	'QUATE',
	'QUAVE',
	'QUDIE',
	'QUEAK',
	'QUEAN',
	'QUECK',
	'QUEED',
	'QUEEF',
	'QUEEL',
	'QUEEM',
	'QUEEN',
	'QUEER',
	'QUEET',
	'QUEFF',
	'QUEIR',
	'QUERD',
	'QUERN',
	'QUICH',
	'QUICK',
	'QUIFF',
	'QUILE',
	'QUILK',
	'QUILL',
	'QUILT',
	'QUINE',
	'QUINK',
	'QUINT',
	'QUIRD',
	'QUIRK',
	'QUIRL',
	'QUITE',
	'QUITH',
	'QUOAD',
	'QUOIT',
	'QUOTE',
	'RAAGA',
	'RAAMI',
	'RAAZE',
	'RACHT',
	'RADDY',
	'RADGE',
	'RADLE',
	'RADLY',
	'RAGLE',
	'RAING',
	'RAISE',
	'RAITH',
	'RAKKI',
	'RALLY',
	'RALPH',
	'RAMLY',
	'RAMMY',
	'RAMPS',
	'RAMSH',
	'RANCE',
	'RANGE',
	'RANYO',
	'RAREY',
	'RARIE',
	'RATCH',
	'RAUNS',
	'RAVEL',
	'REACH',
	'READE',
	'READY',
	'REBAT',
	'REBEL',
	'REBIG',
	'REBUT',
	'RECUR',
	'REEDS',
	'REEHO',
	'REESH',
	'REESK',
	'REEST',
	'REETO',
	'REEVE',
	'REEVO',
	'REEZE',
	'REFER',
	'REIRD',
	'REIVE',
	'REJAG',
	'RELAX',
	'REMIT',
	'RENYE',
	'REPEY',
	'REPLY',
	'RESET',
	'RETCH',
	'REVEL',
	'REVIE',
	'REVIN',
	'RHEUM',
	'RHYME',
	'RIACH',
	'RICHT',
	'RIDER',
	'RIECK',
	'RIEVE',
	'RIFLE',
	'RIGLY',
	'RIGOL',
	'RIMLE',
	'RINCE',
	'RINGE',
	'RINSE',
	'RINYO',
	'RITTO',
	'ROAST',
	'ROBIN',
	'ROGUE',
	'ROLLY',
	'ROOSH',
	'ROOSK',
	'ROOST',
	'ROPER',
	'RORIE',
	'ROSET',
	'ROSIT',
	'ROUGH',
	'ROUND',
	'ROUPY',
	'ROUSE',
	'ROUST',
	'ROUTH',
	'ROVIE',
	'ROVIN',
	'ROWAN',
	'ROWDY',
	'ROWIE',
	'ROWST',
	'ROWTH',
	'ROYAL',
	'ROYET',
	'RUDAS',
	'RUDDY',
	'RUDGE',
	'RUGGY',
	'RUIND',
	'RUISE',
	'RUIVE',
	'RUKKA',
	'RÜLIE',
	'RULLY',
	'RUMSE',
	'RUNCH',
	'RUNGE',
	'RÜTER',
	'RYBAT',
	'RYCHE',
	'RYNEN',
	'SAAIN',
	'SADLE',
	'SAGAN',
	'SAICY',
	'SAINT',
	'SAIRL',
	'SAKTA',
	'SALLE',
	'SALLY',
	'SAMEL',
	'SANDY',
	'SARAH',
	'SARRO',
	'SAUCE',
	'SAUCH',
	'SAUCY',
	'SAUNT',
	'SCAFF',
	'SCALE',
	'SCALK',
	'SCAMP',
	'SCANT',
	'SCAPE',
	'SCARF',
	'SCARP',
	'SCART',
	'SCASH',
	'SCAUD',
	'SCAUP',
	'SCAUR',
	'SCENE',
	'SCENT',
	'SCLAP',
	'SCLAT',
	'SCLIM',
	'SCLIT',
	'SCOFF',
	'SCONE',
	'SCOOF',
	'SCOOT',
	'SCORE',
	'SCORN',
	'SCOTS',
	'SCOUF',
	'SCOUK',
	'SCOUL',
	'SCOUP',
	'SCOUR',
	'SCOUT',
	'SCOVE',
	'SCOWF',
	'SCOWP',
	'SCRAB',
	'SCRAE',
	'SCRAG',
	'SCRAM',
	'SCRAN',
	'SCRAP',
	'SCRAT',
	'SCRAW',
	'SCRAY',
	'SCREE',
	'SCREW',
	'SCRIB',
	'SCRID',
	'SCRIM',
	'SCRIP',
	'SCROG',
	'SCROO',
	'SCROW',
	'SCRUB',
	'SCRUG',
	'SCUDA',
	'SCUFF',
	'SCUIF',
	'SCUIL',
	'SCUIP',
	'SCUIR',
	'SCUIT',
	'SCULE',
	'SCULF',
	'SCULL',
	'SCULT',
	'SCUND',
	'SCURL',
	'SCURR',
	'SCUSH',
	'SEDGE',
	'SEEAN',
	'SEECH',
	'SEECK',
	'SEENT',
	'SEGGY',
	'SELCH',
	'SENSE',
	'SERSH',
	'SERTY',
	'SERVE',
	'SEWCH',
	'SEYTH',
	'SHAAG',
	'SHAAV',
	'SHADE',
	'SHAEK',
	'SHAEL',
	'SHAFS',
	'SHAFT',
	'SHAIF',
	'SHAIR',
	'SHALG',
	'SHALK',
	'SHALP',
	'SHAME',
	'SHAMP',
	'SHANE',
	'SHANG',
	'SHANK',
	'SHAPE',
	'SHARD',
	'SHARE',
	'SHARF',
	'SHARG',
	'SHARK',
	'SHARL',
	'SHASS',
	'SHAUM',
	'SHAUN',
	'SHAUP',
	'SHAUR',
	'SHAVS',
	'SHAWL',
	'SHEAR',
	'SHEEG',
	'SHEEK',
	'SHEEL',
	'SHEEN',
	'SHEEP',
	'SHEER',
	'SHEET',
	'SHELL',
	'SHENT',
	'SHERM',
	'SHESS',
	'SHETH',
	'SHIAK',
	'SHIEL',
	'SHIFT',
	'SHILL',
	'SHILP',
	'SHINE',
	'SHIRE',
	'SHIRK',
	'SHIRL',
	'SHIRP',
	'SHIRT',
	'SHIVE',
	'SHOCK',
	'SHODE',
	'SHOGH',
	'SHONY',
	'SHOOI',
	'SHOOL',
	'SHOOM',
	'SHOOT',
	'SHORD',
	'SHORE',
	'SHORT',
	'SHOTT',
	'SHOUR',
	'SHOUT',
	'SHOWD',
	'SHOWK',
	'SHOWL',
	'SHRUG',
	'SHUET',
	'SHUGE',
	'SHUIL',
	'SHUIR',
	'SHUIT',
	'SHURF',
	'SHURG',
	'SHURL',
	'SHUSH',
	'SHUSY',
	'SHÜTA',
	'SHUTE',
	'SHUVE',
	'SHYVE',
	'SICHT',
	'SIEGE',
	'SIEVE',
	'SILLY',
	'SIMMY',
	'SINLO',
	'SINNA',
	'SINSE',
	'SINYO',
	'SIRRY',
	'SITHE',
	'SITTA',
	'SIVEN',
	'SKAAB',
	'SKAAG',
	'SKAAS',
	'SKAIK',
	'SKAIL',
	'SKAIR',
	'SKALL',
	'SKALV',
	'SKANE',
	'SKARE',
	'SKARP',
	'SKATE',
	'SKATT',
	'SKAVE',
	'SKEAN',
	'SKECH',
	'SKEEB',
	'SKEEG',
	'SKEEL',
	'SKEEM',
	'SKEER',
	'SKEET',
	'SKEGG',
	'SKEGH',
	'SKELB',
	'SKELF',
	'SKELL',
	'SKELP',
	'SKEMP',
	'SKERP',
	'SKERT',
	'SKEUK',
	'SKEUT',
	'SKEWL',
	'SKEYF',
	'SKIBO',
	'SKICE',
	'SKIDE',
	'SKIDO',
	'SKIEN',
	'SKIFF',
	'SKIFT',
	'SKIGG',
	'SKILM',
	'SKILT',
	'SKIME',
	'SKIMP',
	'SKINK',
	'SKINT',
	'SKIRE',
	'SKIRL',
	'SKIRP',
	'SKIRR',
	'SKIRT',
	'SKITE',
	'SKIVE',
	'SKOBE',
	'SKOGE',
	'SKOIL',
	'SKOIT',
	'SKOLE',
	'SKOOM',
	'SKOOT',
	'SKORD',
	'SKOVO',
	'SKOVY',
	'SKRAN',
	'SKRIT',
	'SKRUE',
	'SKULE',
	'SKULK',
	'SKULL',
	'SKULP',
	'SKULT',
	'SKUML',
	'SKUNE',
	'SKUNK',
	'SKUNT',
	'SKURM',
	'SKURT',
	'SKWIN',
	'SKYBE',
	'SKYLD',
	'SKYLE',
	'SLAAK',
	'SLACK',
	'SLAID',
	'SLAIK',
	'SLAIN',
	'SLAIP',
	'SLAIR',
	'SLAIT',
	'SLAKE',
	'SLAMP',
	'SLANG',
	'SLANK',
	'SLASH',
	'SLATE',
	'SLAVE',
	'SLAWK',
	'SLEBS',
	'SLECK',
	'SLEEK',
	'SLEEP',
	'SLENT',
	'SLERP',
	'SLEUG',
	'SLIDE',
	'SLIME',
	'SLING',
	'SLINK',
	'SLIPE',
	'SLISK',
	'SLITE',
	'SLIVE',
	'SLOAN',
	'SLOAP',
	'SLOAT',
	'SLOCH',
	'SLOCK',
	'SLOGG',
	'SLOIT',
	'SLOOM',
	'SLOOT',
	'SLOPE',
	'SLORK',
	'SLORP',
	'SLOTT',
	'SLOUM',
	'SLOUR',
	'SLOUT',
	'SLUCH',
	'SLUFF',
	'SLUMP',
	'SLUNG',
	'SLUNK',
	'SLUPE',
	'SLUSH',
	'SLUTE',
	'SLYPE',
	'SLYTE',
	'SMACK',
	'SMAIK',
	'SMASH',
	'SMEAR',
	'SMEEG',
	'SMEEK',
	'SMELL',
	'SMELT',
	'SMERT',
	'SMICK',
	'SMIFF',
	'SMILE',
	'SMIRD',
	'SMIRK',
	'SMIRL',
	'SMIRN',
	'SMIRR',
	'SMITE',
	'SMITH',
	'SMOCH',
	'SMOIK',
	'SMOIT',
	'SMOKE',
	'SMOLT',
	'SMOOK',
	'SMOOL',
	'SMOOT',
	'SMORA',
	'SMORE',
	'SMOWE',
	'SMOWT',
	'SMUCK',
	'SMUIK',
	'SMUIL',
	'SMUIR',
	'SMULT',
	'SMURL',
	'SMUSH',
	'SMYLE',
	'SMYTE',
	'SNAAG',
	'SNAAR',
	'SNACK',
	'SNAFF',
	'SNAIG',
	'SNAIK',
	'SNAIL',
	'SNAIP',
	'SNAKE',
	'SNANG',
	'SNARK',
	'SNARL',
	'SNASH',
	'SNEAN',
	'SNECK',
	'SNEER',
	'SNEET',
	'SNELL',
	'SNEPP',
	'SNEUG',
	'SNEUK',
	'SNEUT',
	'SNIBB',
	'SNIFF',
	'SNIFT',
	'SNIPE',
	'SNIRK',
	'SNIRL',
	'SNIRT',
	'SNITE',
	'SNOIT',
	'SNOKE',
	'SNOOL',
	'SNORE',
	'SNORK',
	'SNORL',
	'SNORT',
	'SNOSH',
	'SNOUT',
	'SNUEE',
	'SNUFF',
	'SNUID',
	'SNUIL',
	'SNUIT',
	'SNUSH',
	'SNUVE',
	'SNYSE',
	'SNYTH',
	'SOART',
	'SOBER',
	'SODIE',
	'SODOM',
	'SOHOD',
	'SOIND',
	'SOLAN',
	'SOLDU',
	'SOLID',
	'SOLUM',
	'SOLVE',
	'SONSE',
	'SONSY',
	'SOOCH',
	'SOOER',
	'SOOLP',
	'SOORN',
	'SOOSH',
	'SOOTH',
	'SOOZE',
	'SORIT',
	'SORRA',
	'SOUCH',
	'SOUFT',
	'SOUGH',
	'SOUND',
	'SOUSE',
	'SOUTH',
	'SOVIE',
	'SOWAN',
	'SOWCE',
	'SOWER',
	'SOWFF',
	'SOWTH',
	'SPACE',
	'SPADE',
	'SPAIG',
	'SPAIK',
	'SPAIL',
	'SPALD',
	'SPANG',
	'SPANK',
	'SPARE',
	'SPARK',
	'SPARL',
	'SPARR',
	'SPART',
	'SPASH',
	'SPATE',
	'SPAUL',
	'SPAVE',
	'SPEAK',
	'SPEAN',
	'SPEAR',
	'SPEED',
	'SPEEK',
	'SPEEL',
	'SPEER',
	'SPEET',
	'SPEIR',
	'SPELD',
	'SPELK',
	'SPELL',
	'SPELT',
	'SPEND',
	'SPENN',
	'SPEUG',
	'SPIAE',
	'SPICE',
	'SPICK',
	'SPIEL',
	'SPILE',
	'SPILK',
	'SPILL',
	'SPINK',
	'SPIRE',
	'SPIRL',
	'SPITE',
	'SPLAD',
	'SPLAE',
	'SPLAY',
	'SPLEW',
	'SPLIT',
	'SPLOY',
	'SPOAG',
	'SPOCK',
	'SPOLP',
	'SPONG',
	'SPOOR',
	'SPOUS',
	'SPOUT',
	'SPRAG',
	'SPRAT',
	'SPREE',
	'SPRET',
	'SPRIG',
	'SPROG',
	'SPROO',
	'SPROT',
	'SPRUD',
	'SPRUG',
	'SPUIR',
	'SPULE',
	'SPULP',
	'SPUND',
	'SPUNE',
	'SPUNG',
	'SPUNK',
	'SPURD',
	'SPURE',
	'SPURG',
	'SPURL',
	'SPYLE',
	'SPYOG',
	'SQUAT',
	'SQUAW',
	'SQUIR',
	'STAAG',
	'STACK',
	'STAFE',
	'STAFF',
	'STAGE',
	'STAIG',
	'STAIK',
	'STAIR',
	'STAKE',
	'STALE',
	'STALK',
	'STAMP',
	'STAND',
	'STANE',
	'STANG',
	'STANK',
	'STARK',
	'STARN',
	'START',
	'STATE',
	'STAUK',
	'STAUL',
	'STAUP',
	'STAUR',
	'STAVE',
	'STEAK',
	'STEAL',
	'STEAM',
	'STECH',
	'STEEK',
	'STEEL',
	'STEEN',
	'STEEP',
	'STEER',
	'STEFE',
	'STEID',
	'STELL',
	'STEND',
	'STENT',
	'STERE',
	'STEUT',
	'STICK',
	'STIEL',
	'STIFE',
	'STIFF',
	'STILK',
	'STILL',
	'STILP',
	'STILT',
	'STIME',
	'STING',
	'STINK',
	'STINT',
	'STIRK',
	'STIRL',
	'STIRN',
	'STITH',
	'STIVE',
	'STOCK',
	'STOIL',
	'STOIT',
	'STOLE',
	'STOLL',
	'STOLM',
	'STOND',
	'STONE',
	'STOOD',
	'STOOG',
	'STOOK',
	'STOOM',
	'STOOP',
	'STOOR',
	'STORE',
	'STORG',
	'STORM',
	'STORY',
	'STOUP',
	'STOUR',
	'STOUT',
	'STOVE',
	'STOWF',
	'STOWL',
	'STOWP',
	'STRAB',
	'STRAE',
	'STRAG',
	'STRAM',
	'STRAP',
	'STRAW',
	'STRAY',
	'STREW',
	'STRIB',
	'STRIK',
	'STRIP',
	'STRIV',
	'STROD',
	'STROP',
	'STROW',
	'STRUD',
	'STRUG',
	'STRUM',
	'STRYN',
	'STUCK',
	'STUFF',
	'STUGG',
	'STUIL',
	'STUIR',
	'STUIT',
	'STUMP',
	'STUNK',
	'STUNT',
	'STUPE',
	'STURE',
	'STURN',
	'STURT',
	'STYAG',
	'STYAN',
	'STYLE',
	'SUCKY',
	'SUDGE',
	'SUILK',
	'SUISS',
	'SUIST',
	'SUITH',
	'SUIZE',
	'SUMPH',
	'SUNIE',
	'SUPHY',
	'SURRL',
	'SUYAN',
	'SWAAM',
	'SWAAR',
	'SWACK',
	'SWADD',
	'SWADE',
	'SWAGE',
	'SWAIF',
	'SWAIL',
	'SWAIP',
	'SWALL',
	'SWAMP',
	'SWANK',
	'SWARF',
	'SWARM',
	'SWART',
	'SWASH',
	'SWATS',
	'SWEAP',
	'SWECH',
	'SWEEG',
	'SWEEL',
	'SWEEM',
	'SWEEP',
	'SWEER',
	'SWEET',
	'SWEIG',
	'SWEIR',
	'SWELT',
	'SWICK',
	'SWIFF',
	'SWIFT',
	'SWILL',
	'SWIMP',
	'SWINE',
	'SWING',
	'SWINK',
	'SWINT',
	'SWIPE',
	'SWIRE',
	'SWIRL',
	'SWISK',
	'SWITE',
	'SWITH',
	'SWOOF',
	'SWURD',
	'SWYPE',
	'SYBOE',
	'SYBOW',
	'SYMEA',
	'SYMIE',
	'SYNOD',
	'SYPLE',
	'SYTHE',
	'SYVER',
	'TAAVE',
	'TABLE',
	'TACHT',
	'TADDY',
	'TAIRT',
	'TAKIE',
	'TALER',
	'TALLY',
	'TAMEL',
	'TANGS',
	'TANSY',
	'TANTY',
	'TAPEE',
	'TAPER',
	'TARAN',
	'TARDS',
	'TARGE',
	'TARIE',
	'TARLE',
	'TARRY',
	'TASTE',
	'TATHE',
	'TAUCH',
	'TAUDY',
	'TAWNY',
	'TAWSE',
	'TCHAD',
	'TCHOP',
	'TEACH',
	'TEASE',
	'TEEDY',
	'TEESE',
	'TEIND',
	'TEIOT',
	'TEMBA',
	'TENON',
	'TENOR',
	'TERCE',
	'TERRA',
	'TERSE',
	'TETUS',
	'TEUCH',
	'TEVEL',
	'TEVEN',
	'THAAN',
	'THACK',
	'THAFT',
	'THAIR',
	'THANE',
	'THANG',
	'THANK',
	'THARF',
	'THEAT',
	'THEEA',
	'THEEF',
	'THEEK',
	'THEFT',
	'THEIR',
	'THERE',
	'THICK',
	'THIEF',
	'THILL',
	'THING',
	'THINK',
	'THIRD',
	'THIRL',
	'THOCK',
	'THOLE',
	'THORL',
	'THORN',
	'THORT',
	'THOUM',
	'THOWL',
	'THOWS',
	'THOWT',
	'THRAB',
	'THRAE',
	'THRAM',
	'THRAW',
	'THREE',
	'THROM',
	'THROU',
	'THRUM',
	'THRYM',
	'THUMP',
	'TICHT',
	'TIDDO',
	'TIDIN',
	'TIMTY',
	'TINDA',
	'TINTE',
	'TIRCH',
	'TIRMA',
	'TIRSE',
	'TIRVE',
	'TITLE',
	'TITTO',
	'TJARF',
	'TJOAG',
	'TOAST',
	'TOGAL',
	'TOKEN',
	'TOKIE',
	'TOMAN',
	'TONIE',
	'TOOCH',
	'TOORL',
	'TOOSE',
	'TOOSH',
	'TOOSK',
	'TOOTA',
	'TORIE',
	'TORRY',
	'TOSIE',
	'TOTAL',
	'TOTCH',
	'TOTUM',
	'TOUCH',
	'TOUSE',
	'TOUSH',
	'TOUST',
	'TOWAL',
	'TOWDY',
	'TOWIN',
	'TOXIE',
	'TRACE',
	'TRACK',
	'TRADE',
	'TRAFF',
	'TRAIK',
	'TRAIL',
	'TRAIN',
	'TRAIP',
	'TRAIT',
	'TRAMP',
	'TRASH',
	'TRATE',
	'TRAWL',
	'TREAD',
	'TREAT',
	'TRECK',
	'TREEN',
	'TRESS',
	'TRETE',
	'TREWS',
	'TRIAL',
	'TRIEF',
	'TRILL',
	'TRILT',
	'TRING',
	'TRINK',
	'TRIPE',
	'TROAK',
	'TROAP',
	'TROCH',
	'TROGS',
	'TROKE',
	'TROLL',
	'TROLY',
	'TROMB',
	'TROON',
	'TROSK',
	'TROUK',
	'TROUT',
	'TROYT',
	'TRUAN',
	'TRUCK',
	'TRUEL',
	'TRUEN',
	'TRUFF',
	'TRULL',
	'TRULY',
	'TRUMP',
	'TRUMS',
	'TRUNK',
	'TRUSS',
	'TRUST',
	'TRUTS',
	'TRWOO',
	'TRYST',
	'TSILL',
	'TUCHT',
	'TUECH',
	'TUINK',
	'TUINT',
	'TUIRD',
	'TUISH',
	'TUITH',
	'TULLY',
	'TUNAG',
	'TUNCH',
	'TURLS',
	'TURRA',
	'TURSE',
	'TUSSI',
	'TUTOR',
	'TWACK',
	'TWANG',
	'TWAUM',
	'TWEED',
	'TWEEL',
	'TWEEN',
	'TWICE',
	'TWILT',
	'TWINE',
	'TWINK',
	'TWIRK',
	'TWIRL',
	'TWIRN',
	'TWIRR',
	'TWIST',
	'TWITE',
	'TWIXT',
	'TYACH',
	'TYARR',
	'TYNER',
	'TYOLL',
	'TYSTE',
	'TYTER',
	'UCCLE',
	'UDDER',
	'UDDIE',
	'UGGLE',
	'UIGLA',
	'UILIE',
	'UIMRO',
	'UITER',
	'UMBLE',
	'UMIAL',
	'UMOST',
	'UMSHY',
	'UNAGE',
	'UNBUN',
	'UNCAD',
	'UNCLE',
	'UNDER',
	'UNHAP',
	'UNHOT',
	'UNITE',
	'UNKEN',
	'UNLAW',
	'UNLED',
	'UNRED',
	'UNRID',
	'UNSET',
	'UNTAE',
	'UNTIL',
	'UNUSE',
	'UNWON',
	'UPEAT',
	'UPGAE',
	'UPGET',
	'UPGIE',
	'UPMAK',
	'UPPER',
	'UPPIE',
	'UPPIT',
	'UPRIN',
	'UPSET',
	'UPSUN',
	'UPTAK',
	'UREEN',
	'URISK',
	'URLAR',
	'URTER',
	'USEID',
	'USHER',
	'USHET',
	'USUAL',
	'UVART',
	'VAANA',
	'VAARL',
	'VAIGE',
	'VALUE',
	'VANCE',
	'VANDA',
	'VARSE',
	'VAUCH',
	'VAVEL',
	'VEEAT',
	'VEEKO',
	'VEIDS',
	'VENGA',
	'VENOM',
	'VERGE',
	'VICAR',
	'VIDGE',
	'VIEVE',
	'VINYA',
	'VIRPA',
	'VISEK',
	'VITCH',
	'VITTA',
	'VIVDA',
	'VIZZY',
	'VOAMD',
	'VOGIE',
	'VOICE',
	'VONNA',
	'VOOCH',
	'VOUST',
	'VRACK',
	'VUXEN',
	'WAARD',
	'WACHT',
	'WADGE',
	'WAGER',
	'WAIKE',
	'WAITH',
	'WALIE',
	'WALLT',
	'WALLY',
	'WALSH',
	'WALTH',
	'WANCE',
	'WANNY',
	'WANST',
	'WANTY',
	'WARBA',
	'WARLD',
	'WARSE',
	'WARST',
	'WASIE',
	'WASTE',
	'WATCH',
	'WATER',
	'WAUCH',
	'WAULD',
	'WAULK',
	'WAVEL',
	'WAVER',
	'WEAKY',
	'WEARY',
	'WEAVE',
	'WECHT',
	'WEESH',
	'WEESK',
	'WEESO',
	'WEEZE',
	'WEIRD',
	'WENCH',
	'WERSH',
	'WEUCH',
	'WHAAK',
	'WHAAL',
	'WHAAP',
	'WHACK',
	'WHAEN',
	'WHALE',
	'WHALM',
	'WHALP',
	'WHANG',
	'WHARK',
	'WHARL',
	'WHAUP',
	'WHAUR',
	'WHEAN',
	'WHEEK',
	'WHEEL',
	'WHEEM',
	'WHEEN',
	'WHEEP',
	'WHEER',
	'WHEET',
	'WHENK',
	'WHERN',
	'WHESS',
	'WHEWL',
	'WHICK',
	'WHIET',
	'WHIFF',
	'WHILE',
	'WHILK',
	'WHIME',
	'WHINE',
	'WHINK',
	'WHINT',
	'WHIRE',
	'WHIRL',
	'WHIRM',
	'WHIRR',
	'WHISK',
	'WHISS',
	'WHITE',
	'WHOOM',
	'WHOON',
	'WHOOT',
	'WHORE',
	'WHORN',
	'WHOUP',
	'WHUCH',
	'WHULL',
	'WHULT',
	'WHUMP',
	'WHUNG',
	'WHURL',
	'WHUSH',
	'WICHT',
	'WIDEN',
	'WIDOE',
	'WIDOW',
	'WIELD',
	'WILLA',
	'WINCH',
	'WINDY',
	'WINNY',
	'WINYA',
	'WINZE',
	'WISTA',
	'WITCH',
	'WITHA',
	'WIZEN',
	'WIZZY',
	'WOBBY',
	'WOMAN',
	'WORRY',
	'WORTH',
	'WOUND',
	'WOWFF',
	'WRACK',
	'WRAIK',
	'WRAMP',
	'WRANG',
	'WRATH',
	'WREST',
	'WRING',
	'WRIST',
	'WRITE',
	'WROKE',
	'XENON',
	'YAAVE',
	'YABLE',
	'YACHT',
	'YADER',
	'YAIRD',
	'YAIRN',
	'YAMAL',
	'YAMIE',
	'YAMPH',
	'YAMPS',
	'YAMSE',
	'YANCE',
	'YAPEN',
	'YARTA',
	'YAULD',
	'YAVAL',
	'YAVEL',
	'YAVIL',
	'YAWFU',
	'YAWIN',
	'YDANT',
	'YEARN',
	'YEAST',
	'YEERY',
	'YEESE',
	'YEESP',
	'YELLA',
	'YENCE',
	'YEPIE',
	'YETIN',
	'YETTY',
	'YEUCH',
	'YEVIE',
	'YIELD',
	'YINCE',
	'YINST',
	'YIRLS',
	'YIRMS',
	'YIRPO',
	'YIRSE',
	'YITSA',
	'YOGER',
	'YOGLE',
	'YOKIN',
	'YOKUL',
	'YONSI',
	'YORNT',
	'YOUCH',
	'YOUNG',
	'YOURN',
	'YOUST',
	'YOUTH',
	'YOWFF',
	'YUINK',
	'ZEAST',
	'ZEBRA',
]

if (CONFIG.normalization) {
  VALIDGUESSES.forEach(
    (val, i) => (VALIDGUESSES[i] = val.normalize(CONFIG.normalization))
  )
}